import React from "react";

export function SignUp(){
    return (
        <React.Fragment>
            <h3 className="dashboard-content">This is the Sign Up Landing Page</h3>
        </React.Fragment>
    
    )
    
}